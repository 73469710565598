/**
 * React core
 */
import React from 'react'
import ReactDOM from 'react-dom'

/**
 * Polotno core
 */
import {observer} from 'mobx-react-lite'
import {createStore} from 'polotno/model/store'
import FaShapes from '@meronex/icons/fa/FaShapes'
import {downloadFile} from 'polotno/utils/download'

/**
 * Polotno components
 */
import {Toolbar} from 'polotno/toolbar/toolbar'
import {Workspace} from 'polotno/canvas/workspace'
import {
	SectionTab,
	TextSection,
	PhotosSection,
	ElementsSection,
	BackgroundSection,
	SizeSection,
	SidePanel,
} from 'polotno/side-panel'

/**
 * Blueprint components
 */
import {Button} from '@blueprintjs/core'
import {NumericInput} from '@blueprintjs/core'

/**
 * Translations
 */

import {setTranslations} from 'polotno/config'
setTranslations({
	sidePanel: {
		text: 'Тexte',
		myFonts: 'Mes polices',
		background: 'Arrière-plan',
		noResults: 'Pas de résultats',
		otherFormats: 'Autres formats',
		resize: 'Redimensionner',
		searchPlaceholder: 'Faire une recherche...',
		searchTemplatesWithSameSize: 'Afficher les templates de même taille',
		upload: 'Héberger',
		uploadFont: 'Héberger une police',
		uploadImage: 'Héberger une image',
		uploadTip: 'Souhaitez-vous héberger votre image?',
		useMagicResize: 'Redimensionner automatiquement',
	},
	toolbar: {
		alignBottom: 'Aligner en bas',
		alignCenter: 'Centrer horizontalement',
		alignLeft: 'Aligner à gauche',
		alignMiddle: 'Centrer verticalement',
		alignRight: 'Aligner à droite',
		alignTop: 'Aligner en haut',
		blur: 'Flou',
		border: 'Bordure',
		brightness: 'Luminosité',
		cornerRadius: 'Arrondi des bords',
		crop: 'Recadrer',
		cropCancel: 'Annuler',
		cropDone: 'Terminé!',
		download: 'Télécharger',
		effects: 'Effets',
		flip: 'Retourner',
		flipHorizontally: 'Retourner horizontalement',
		flipVertically: 'Retourner verticalement',
		grayscale: 'Niveaux de gris',
		opacity: 'Opacité',
    textStroke: 'Contour de texte',
    shadow: 'Ombre',
		removeElements: 'Supprimer',
		saveAsImage: 'Enregistrer',
		duplicateElements: 'Dupliquer',
		layering: 'Superposition',
    unlockedDescription: 'Verouiller cet élément',
		lockedDescription: 'Dévérouiller cet élément',
		down: 'Descendre une fois',
		toBottom: 'Tout en bas',
		up: 'Remonter une fois',
		toForward: 'Tout en haut',
		transparency: 'Transparence',
	},
})

/**
 * Utils
 */
import Logo from '../src/img/logo.png'

/**
 * App
 */
const store = createStore({
	key: 'zEvqPBPceGAURmPcT92E',
	showCredit: true,
})

// make global for debug
window.store = store

store.setSize(1000 * (150 / 25.4), 1000 * (150 / 25.4))

store.addPage()
store.activePage.addElement({
	type: 'text',
	text: 'Commencez à créer votre panneau avec',
	x: 100 * (150 / 25.4),
	y: 300 * (150 / 25.4),
	fontSize: 450,
	width: 800 * (150 / 25.4),
	align: 'center',
	fontFamily: 'Manrope',
})
store.activePage.addElement({
	type: 'image',
	src: Logo,
	x: 50 * (150 / 25.4),
	y: 550 * (150 / 25.4),
	width: 900 * (150 / 25.4),
	height: 150 * (150 / 25.4),
})

// define the new custom section
const ResizeSection = {
	name: 'resize',
	Tab: (props) => (
		<SectionTab name="Resize" {...props}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="28.514"
				height="23.329"
				viewBox="0 0 28.514 23.329"
			>
				<path
					id="Icon_material-photo-size-select-small"
					data-name="Icon material-photo-size-select-small"
					d="M30.014,20.053H27.422v2.592h2.592Zm0-5.184H27.422v2.592h2.592Zm0,10.369H27.422v2.592A2.788,2.788,0,0,0,30.014,25.237ZM19.645,4.5H17.053V7.092h2.592ZM30.014,9.684H27.422v2.592h2.592ZM27.422,4.5V7.092h2.592A2.788,2.788,0,0,0,27.422,4.5ZM4.092,27.829H14.461V20.053H1.5v5.184A2.6,2.6,0,0,0,4.092,27.829Zm0-18.145H1.5v2.592H4.092ZM19.645,25.237H17.053v2.592h2.592ZM24.829,4.5H22.237V7.092h2.592Zm0,20.737H22.237v2.592h2.592ZM4.092,4.5A2.788,2.788,0,0,0,1.5,7.092H4.092Zm0,10.369H1.5v2.592H4.092ZM14.461,4.5H11.869V7.092h2.592Zm-5.184,0H6.684V7.092H9.276Z"
					transform="translate(-1.5 -4.5)"
				/>
			</svg>
		</SectionTab>
	),
	// we need observer to update component automatically on any store changes
	Panel: observer(({store}) => {
		return (
			<div className="resize-panel">
				<div className="resize-panel__group">
					<span>Hauteur (en milimètres)</span>
					<NumericInput
						placeholder="1000 par défaut"
						value={store.value}
						onValueChange={(e) => {
							// convert milimeters to pixels
							store.setSize(e * (150 / 25.4), store.height)
						}}
					/>
				</div>
				<div className="resize-panel__group">
					<span>Largeur (en milimètres)</span>
					<NumericInput
						placeholder="1000 par défaut"
						value={store.value}
						onValueChange={(e) => {
							store.setSize(store.width, e * (150 / 25.4))
						}}
					/>
				</div>
				<p>
					Notre configurateur fonctionne en milimètres, puisque c'est la mesure
					la plus pratique et la plus répandue pour de l'impression
					signalétique. Vérifiez donc bien que vous entrez des valeurs en
					milimètres, car en cas d'erreur, Signaléclic ne peut se tenir
					responsable.
				</p>
			</div>
		)
	}),
}

const sections = [
	ResizeSection,
	TextSection,
	PhotosSection,
	ElementsSection,
	BackgroundSection,
]

const App = ({store}) => {
	const inputRef = React.useRef()

	return (
		<div>
			<div
				style={{
					display: 'flex',
					height: 'calc(100vh - 8rem)',
					width: '100vw',
				}}
			>
				<div style={{width: '500px', height: '100%', display: 'flex'}}>
					<SidePanel
						store={store}
						sections={sections}
						defaultSection="resize"
					/>
				</div>
				<div
					style={{
						display: 'flex',
						height: '100%',
						margin: 'auto',
						flex: 1,
						flexDirection: 'column',
						position: 'relative',
					}}
				>
					<Toolbar store={store} downloadButtonEnabled={true} />
					<Workspace store={store} pageControlsEnabled={false} />
				</div>
			</div>
			<div className="app-footer">
				<div className="app-footer__buttons">
					<label htmlFor="load-project">
						<Button
							className="app-btn app-btn--secondary"
							icon="folder-open"
							minimal
							onClick={() => {
								document.querySelector('#load-project').click()
							}}
						>
							Ouvrir un fichier
						</Button>
						<input
							type="file"
							id="load-project"
							accept=".json,.polotno"
							ref={inputRef}
							style={{width: '180px', display: 'none'}}
							onChange={(e) => {
								var input = e.target

								if (!input.files.length) {
									return
								}

								var reader = new FileReader()
								reader.onloadend = function () {
									var text = reader.result
									let json
									try {
										json = JSON.parse(text)
									} catch (e) {
										alert('Can not load the project.')
									}

									if (json) {
										store.loadJSON(json)
									}
								}
								reader.onerror = function () {
									alert('Can not load the project.')
								}
								reader.readAsText(input.files[0])
							}}
						/>
					</label>
					<Button
						className="app-btn app-btn--main"
						icon="floppy-disk"
						minimal
						onClick={() => {
							const json = store.toJSON()
							const url =
								'data:text/json;base64,' +
								window.btoa(unescape(encodeURIComponent(JSON.stringify(json))))
							downloadFile(url, 'polotno.json')
						}}
					>
						Enregistrer
					</Button>
				</div>
			</div>
		</div>
	)
}

ReactDOM.render(<App store={store} />, document.getElementById('root'))
